import React from 'react'
import '../assets/footer.css'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer'>
      <p>Copyright National Green Pages©2018. All Rights Reserved</p>
    </div>
  )
}

export default Footer