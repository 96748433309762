import React from 'react'
import '../assets/dashboard.css'
import Header from './Header'
import Footer from './Footer'

const Dashboard = () => {
  return (
    <div>
    <Header/>
        <div className='dash'>
            
        </div>
        <Footer/>
    </div>
  )
}

export default Dashboard