import React from 'react'
import '../assets/advert.css'
import Header from './Header'
import Footer from './Footer'

const Advert = () => {
  return (
    <div>
    <Header/>
    <div className='advert-contents'>
    
    </div>
    <Footer/>
    </div>
  )
}

export default Advert